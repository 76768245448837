import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './admindashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import defaultImg from '../../resources/images/default.png';
import loginLogo from '../../resources/images/verdex-bgremove.png';
import blocklogo from '../../resources/images/verdexblock-removebg.png';
import { FaAlignLeft, FaAlignRight, FaSignOutAlt, FaUserAlt } from 'react-icons/fa';
import AdminSidebar from './AdminSidebar';
import { useAuthContext } from '../../hooks/UseAuthContext';
import Loader from '../Loader/Loader';

function AdminDashboard() {
    const [queryData, setQueryData] = useState({
        currencies: null,
        users: null,
    });
    const [shouldMenuSlide, setShouldMenuSlide] = useState(false);
    const [dashUser, setDashUser] = useState(false);
    const [sidemenu, setSideMenu] = useState(false);
    const { user, dispatch } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const logout = useCallback(() => {
        dispatch({ type: 'LOGOUT', payload: null });
        localStorage.setItem('user', null);
        
            navigate('/login-admin');
      
    }, [dispatch, navigate]);

    useEffect(() => {
      const fetchData = async () => {
          if (!user?.token) return; // Ensure the token is available
  
          const cachedCurrencies = localStorage.getItem('walletTokens'); // Check for cached data
  
          setLoading(true); // Start loading state
  
          try {
              // Conditionally run the Axios request for currencyResponse only if no cached data
              const currencyPromise = cachedCurrencies
                  ? Promise.resolve(JSON.parse(cachedCurrencies)) // Use cached data if it exists
                  : axios.get(`${process.env.REACT_APP_API_URL}/crypto/get-wallet-tokens`, {
                      headers: {
                          Authorization: `Bearer ${user.token}`,
                      },
                  }).then(response => {
                      localStorage.setItem('walletTokens', JSON.stringify(response.data)); // Save data to local storage
                      return response.data; // Return the fetched data
                  });
  
              // Fetch the users regardless of local storage
              const usersPromise = axios.get(`${process.env.REACT_APP_API_URL}/user/get-users`, {
                  headers: {
                      Authorization: `Bearer ${user.token}`,
                  },
              });
  
              // Await both promises
              const [currencyResponse, usersResponse] = await Promise.all([currencyPromise, usersPromise]);
  
              // Update state with both fetched (or cached) data
       
              setQueryData({
                  users: usersResponse.data.length,
                  currencies: currencyResponse.length, // Use the length of the currencyResponse array
              });
          } catch (error) {
              console.error('Error fetching data:', error);
          } finally {
              setLoading(false); // Stop loading state
          }
      };
  
      fetchData();
  }, [user?.token]); // Dependency on user token
  

    return (
        <div className="admin dashboard">
            <div className="dashboard-header">
                <div className="rights-side">
                    <Link to="/" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        <div className="admin-logo">
                            <img src={loginLogo} alt="logo" />
                        </div>
                    </Link>
                    <FaAlignLeft onClick={() => {
                        setShouldMenuSlide(true);
                        setSideMenu(!sidemenu);
                    }} />
                </div>
                <div className="left-side">
                    <FaUserAlt onClick={() => setDashUser(!dashUser)} />
                </div>
                {dashUser && (
                    <div className="user-menu">
                        <p className="logout" onClick={logout}>
                            <FaSignOutAlt />Logout
                        </p>
                    </div>
                )}
            </div>
            {sidemenu && (
                <AdminSidebar shouldMenuSlide={shouldMenuSlide} />
            )}
            <div className="main-admin-dash activities">
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="card-body activit1">
                            <p>Users</p>
                            <span>{queryData.users}</span>
                            <Link style={{ textDecoration: 'none', color: 'white' }} to={'/admin/users'}>
                                <h3>Manage &#8594;</h3>
                            </Link>
                        </div>
                        <div className="card-body activit2">
                            <p>Crypto Currencies</p>
                            <span>{queryData.currencies}</span>
                            <Link style={{ textDecoration: 'none', color: 'white' }} to={'/admin/tokens'}>
                                <h3>Manage &#8594;</h3>
                            </Link>
                        </div>
                        <div className="card-body activit3">
                            <p>Notifications</p>
                            <Link style={{ textDecoration: 'none', color: 'white' }} to={'/admin/notify'}>
                                <h3>Manage &#8594;</h3>
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default AdminDashboard;
