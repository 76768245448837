import React, { useEffect, useState } from 'react';
import './home.css';
import Header from '../../components/Header/Header';
import heroImg from '../../resources/images/buy-btc.png';
import axios from 'axios';
import FAQItem from '../../components/FAQ/FAQItem';
import simpleImage from '../../resources/images/simpleseamless.svg'
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { toast } from 'wc-toast';
function Home() {
  const [data, setData] = useState(null);
  const [email,setEmail]=useState("")

  useEffect(() => {
    // Fetch crypto data from API
    axios.get(`${process.env.REACT_APP_API_URL}/crypto/get-crypto`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      setData(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  // Function to calculate the percentage change for a single token
  const calculateChange = (token) => {
    const percentageIncrease = ((token.current_price - token.low_24h) / token.low_24h) * 100;
    const percentageDecrease = ((token.high_24h - token.current_price) / token.high_24h) * 100;
    const overallChange = token.price_change_percentage_24h;

    return { percentageIncrease, percentageDecrease, overallChange };
  };

  // Conditionally render the change as green for positive and red for negative
  const renderChange = (change) => {
    if (change > 0) {
      return <span style={{ color: 'rgb(0, 178, 107)' }}>+{change.toFixed(2)}%</span>;
    } else if (change < 0) {
      return <span style={{ color: 'red' }}>{change.toFixed(2)}%</span>;
    } else {
      return <span>{change.toFixed(2)}%</span>;
    }
  };
  const handleSignup=()=>{
    setEmail("")
    toast.success("Successfully signed up for our newsletters")

  }

  return (
    <div className='home'>
      <div className="homeheader">
        <Header />
      </div>
      <div className="hero-section">
        <div className='hero-text'>
          <h2>The future of finance</h2>
          <p>Buy Bitcoin, Ethereum, and other leading cryptocurrencies on a platform trusted by millions.</p>

          <div className="newsletter">
            <input type="email" placeholder='Your email address' onChange={(e)=>{
              setEmail(e.target.value)

            }} value={email}/>
            <button onClick={()=>{
              handleSignup()

            }}>Sign up</button>
          </div>
        </div>
        <div className="hero-img">
          <img src={heroImg} alt="" />
        </div>
      </div>

      <div className="home-market">
        {data && data.slice(0, 8).map((token) => {
          const { percentageIncrease, percentageDecrease, overallChange } = calculateChange(token);
          return (
            <div className='home-market-token' key={token.id}>
              <img src={token.image} alt={token.name} />
              <p>{token.name} <span>{token.symbol}</span></p>
              <p className='home-market-token-price'>${token.current_price} <span>{renderChange(overallChange)}</span></p>

            </div>
          );
        })}
      </div>
      <div className='home-faq'>
      <FAQItem />
      </div>

      <div className="simple">
        <div className="simple-first">
        <h2>Simple and Seamless.</h2>
        <p> Enjoy a smooth mobile app and desktop experience with easy-to-use, powerful tools to support your entire Web3 journey.</p>
        
        </div>
        <div className="simple-second">
           <div>
           <h3>Deposit crypto easily from exchanges</h3>
           <p>Take control of your crypto. Avoid complicated steps and deposit directly to your wallet from exchanges like Binance and Coinbase.</p>
            </div> 
            <div>
                <img src={simpleImage} alt="" />
            </div>

        </div>
      </div>

      <div className="get-started">
        <h3>Let's Get Started</h3>
        <button><Link to='/login' style={{textDecoration:'none',color:'white'}} >Sign Up Now</Link></button>
      </div>
      <div className="home-footer">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
