import React, { useCallback } from 'react'
import './footer.css'
import footerLogo from '../../resources/images/mobmenuheader.png'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/UseAuthContext'
function Footer() {
  const {user,dispatch}=useAuthContext()
  const navigate = useNavigate();

  const logout = useCallback(() => {
    dispatch({ type: 'LOGOUT', payload: null });
    localStorage.setItem('user', null);
    
        navigate('/login');
  
}, [dispatch, navigate]);
  return (
    <div className='footer'>

       <div className="section-1">
       <div className="footer-verdex">
            {/* <h2>Verdexchain</h2> */}
            <Link to='/'><img src={footerLogo} alt="" /></Link>
        </div>

        <div className='footer-resources'>
        <h2>Resources</h2>
        <Link to='/wallet' style={{textDecoration:'none', color:'#fff'}}><p>Wallet</p></Link>
         <Link to='/market' style={{textDecoration:'none', color:'#fff'}}><p>Market</p></Link>
         <Link to='/ticket' style={{textDecoration:'none', color:'#fff'}}><p>Contact Us</p></Link>

        </div>

        <div className="footer-auth">
        <h2>Authentication</h2>
        {
        !user?(<>
        <button><Link to='/login' style={{textDecoration:'none', color:'black'}}>Login</Link></button>
        <button><Link to='/signup' style={{textDecoration:'none',color:'white'}} >Sign Up</Link></button>
        
        </>):(
            <button onClick={logout}>Logout</button>
          )
       }
        </div>
       </div>

       <div className="copyright">
        <p>Verdexchain© 2024</p>
       </div>

      
    </div>
  )
}

export default Footer
