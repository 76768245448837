import React, { useState, useEffect } from 'react';
import { FaAlignLeft, FaSignOutAlt, FaUserAlt } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'wc-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/UseAuthContext';
import AdminSidebar from './AdminSidebar';

function Notify() {
  const [dashUser, setDashUser] = useState(false);
  const [data, setData] = useState([]);
  const { user, dispatch } = useAuthContext();
  const [formData, setFormData] = useState({
    title: '',
    message: '',
    userId: ''
  });
  const [sideMenu, setSideMenu] = useState(false);
  const [btnState, setBtnState] = useState('Submit');
  const [shouldMenuSlide, setShouldMenuSlide] = useState(false);
  const navigate=useNavigate()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-users`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        console.log(response.data)
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (user && user.token) {
      fetchUsers();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnState('Processing...');

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/send-notifiction/${formData.userId}`, formData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setBtnState('Submit');
      setFormData({
        title: '',
        message: '',
        userId: ''
      });

      toast.success('User Notified Successfully');
    } catch (error) {
      setBtnState('Submit');
      toast.error(error.response?.data?.error || 'Error sending notification');
    }
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT', payload: null });
    localStorage.setItem('user', null);
        
            navigate('/login-admin');
  };

  return (
    <div className='dashboard'>
      <div className="dashboard-header">
        <div className="rights-side">
          <Link to='/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            <p>{process.env.REACT_APP_APP_NAME}</p>
          </Link>
          <FaAlignLeft onClick={() => {
            setShouldMenuSlide(true);
            setSideMenu(!sideMenu);
          }} />
        </div>
        <div className="left-side">
          <FaUserAlt onClick={() => setDashUser(!dashUser)} />
        </div>
        {dashUser && (
          <div className="user-menu">
            <Link to='/user-profile' style={{ textDecoration: 'none', color: '#6e6e6e', marginTop: '.8rem' }}>
              <p>Profile Overview</p>
            </Link>
            <Link to='/profile-setting' style={{ textDecoration: 'none', color: '#6e6e6e', marginTop: '.8rem' }}>
              <p>Profile Settings</p>
            </Link>
            <p className='logout' onClick={logout}><FaSignOutAlt />Logout</p>
          </div>
        )}
      </div>

      <div className="dashboard-main-section">
        {sideMenu && (
          <AdminSidebar shouldMenuSlide={shouldMenuSlide} />
        )}
        <div className="send-money-container">
          <form className="send-money-form" onSubmit={handleSubmit}>
            <h2>Notify User</h2>

            <div className="send-money-form-group">
              <label htmlFor="userId">Choose User To Message<span>*</span></label>
              <select
                id="userId"
                value={formData.userId}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select One</option>
                {data.length > 0 && data.map((user) => (
                  <option key={user._id} value={user._id}>{user.name}</option>
                ))}
              </select>
            </div>

            <div className="send-money-grid">
              <div className="send-money-form-group">
                <label htmlFor="title">Title<span>*</span></label>
                <input
                  type="text"
                  id="title"
                  placeholder='Title'
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="send-money-form-group">
              <label htmlFor="message">Message <span>*</span></label>
              <textarea
                id="message"
                rows="3"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>

            <button type="submit" className="send-money-send-btn" disabled={btnState === 'Processing...'}>
              {btnState}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Notify;
