import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';

function Market() {
    const [data, setData] = useState(null);

    useEffect(() => {
        // Fetch crypto data from API
        axios.get(`${process.env.REACT_APP_API_URL}/crypto/get-crypto`, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
          setData(response.data);
          console.log(response.data)
        }).catch(error => {
          console.log(error);
        });
      }, []);
        // Function to calculate the percentage change for a single token
  const calculateChange = (token) => {
    const percentageIncrease = ((token.current_price - token.low_24h) / token.low_24h) * 100;
    const percentageDecrease = ((token.high_24h - token.current_price) / token.high_24h) * 100;
    const overallChange = token.price_change_percentage_24h;

    return { percentageIncrease, percentageDecrease, overallChange };
  };


  
  // Conditionally render the change as green for positive and red for negative
  const renderChange = (change) => {
    if (change > 0) {
      return <span style={{ color: 'rgb(0, 178, 107)' }}>+{change.toFixed(2)}%</span>;
    } else if (change < 0) {
      return <span style={{ color: 'red' }}>{change.toFixed(2)}%</span>;
    } else {
      return <span>{change.toFixed(2)}%</span>;
    }
  };
  
  return (
    <div className='market'>
         <Header />
       <div className="home-market">
        {!data ?(<><Loader/></>): data.map((token) => {
          const { percentageIncrease, percentageDecrease, overallChange } = calculateChange(token);
          return (
            <div className='home-market-token' key={token.id}>
              <img src={token.image} alt={token.name} />
              <p>{token.name} <span>{token.symbol}</span></p>
              <p className='home-market-token-price'>${token.current_price} <span>{renderChange(overallChange)}</span></p>

            </div>
          );
        })}
      </div>
      <div className="home-footer">
        <Footer />
      </div>
    </div>
  )
}

export default Market
