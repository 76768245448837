import React, { useState } from 'react';
import './faq.css';

const FAQItem = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: 'What is a cryptocurrency exchange?',
      answer: 'Cryptocurrency exchanges are digital marketplaces that enable users to buy and sell cryptocurrencies like Bitcoin, Ethereum, and Tether. The Binance exchange is the largest crypto exchange by trade volume.Cryptocurrency exchanges are digital marketplaces that enable users to buy and sell cryptocurrencies like Bitcoin, Ethereum, and Tether. The Binance exchange is the largest crypto exchange by trade volume.'
    },
    {
      question: 'How to track cryptocurrency prices',
      answer: 'The easiest way to track the latest cryptocurrency prices, trading volumes, trending altcoins, and market cap is the Verdexchain Cryptocurrency Market Directory. Click on the coins to know historical coin prices, 24-hour trading volume, and the price of cryptocurrencies like Bitcoin, Ethereum, BNB and others in real-time.'
    },
    {
      question: 'What is a cryptocurrency wallet?',
      answer: 'A cryptocurrency wallet is a digital tool that allows you to store, send, and receive cryptocurrencies. It can be a software application (hot wallet) or a physical device (cold wallet).'
    },
    {
      question: 'How do I set up a cryptocurrency wallet on Verdexchain?',
      answer: 'To set up a cryptocurrency wallet on verdexchain, you need to navigate to our sign up page and create your account, once you are verified you will be able to login to your crypto wallet.'
    },
    {
        question: 'Can I recover my wallet if I lose access to it?',
        answer: 'Yes you can, if you have forgotten your password, trying recovering the password in your sign in page. If you have lost your email and cant recover password, just contact support and we will respond to you as soon as possible '
      },
      {
        question: 'What are the differences between hot wallets and cold wallets?',
        answer: 'Hot wallets are connected to the internet and are more convenient for frequent transactions. Cold wallets, such as hardware wallets, are offline and provide enhanced security for long-term storage of cryptocurrencies.'
      },
      {
        question: 'How do I transfer funds from my wallet?',
        answer: 'To transfer funds, you need to enter the recipient`s wallet address and the amount you wish to send in your wallet application. Confirm the transaction details and authorize the transfer. The transaction will be processed on the blockchain network.'
      }
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question scale-in-top" onClick={() => handleToggle(index)}>
              <h3>{faq.question}</h3>
              <span className={`faq-icon ${activeIndex === index ? 'active ' : ''}`}>+</span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer scale-in-top">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQItem;
