import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft, FaQrcode } from 'react-icons/fa';
import QrScanner from 'qr-scanner';
import TransferConfirm from './TransferConfirm';

function SendCoin({ data, setSendCoin }) {
    const [walletAddress, setWalletAddress] = useState('');
    const [amount, setAmount] = useState('');
    const videoRef = useRef(null); // Reference for the QR scanner video
    const [showScanner, setShowScanner] = useState(false); // Toggle scanner
    const [transferData,setTransferData]=useState({})
    const [ready,setReady]=useState(false)
    const [transferReady,setTransferReady]=useState(false)

    // Handle QR scanning
    useEffect(() => {
        if (showScanner) {
            const qrScanner = new QrScanner(
                videoRef.current,
                (result) => {
                    console.log(result)
                    setWalletAddress(result.data);
                    setShowScanner(false); // Hide scanner after successful scan
                },
                {
                    onDecodeError: (error) => console.log(error),
                }
            );
            qrScanner.start();

            return () => {
                qrScanner.stop();
            };
        }
    }, [showScanner]);

    useEffect(()=>{
        console.log(transferData)

        

        if(transferData?.amount>0&&walletAddress){
            setReady(true)
        }

    },[transferData])


    useEffect(()=>{
        setTransferData({
            ...transferData,
            address:walletAddress
        })

    },[walletAddress])

    return (
        <>
        <div className="crypto-send-container">
            <div className="choose-receive-top">
                <span onClick={() => setSendCoin(false)}>
                    <FaArrowLeft />
                </span>
                <p>Send <span>{data.symbol}</span></p>
                <span></span>
            </div>

            <div className="send-section ">
                <form>
                    <div>
                        <label>Address</label>
                        <div className="input-section">
                            <input 
                                type="text" 
                                placeholder="Long press to paste" 
                                value={walletAddress||null}
                                onChange={(e) => {
                                    setWalletAddress(e.target.value)
                                    
                                }}
                            />
                            <p onClick={() => setShowScanner(!showScanner)}>
                                <FaQrcode />
                            </p>
                        </div>
                    </div>

                    {showScanner && (
                        <div className="qr-scanner">
                            <video ref={videoRef} style={{ width: '100%' }} />
                        </div>
                    )}

                    <div>
                        <label>Amount</label>
                        <div className="input-section">
                            <input 
                                type="number" 
                                placeholder="USD Amount"
                                value={transferData?.amount}
                                onChange={(e) => {
                                
                                        setTransferData({
                                            ...transferData,
                                            amount:e.target.value
                                        })
                                }}
                            />
                            <p>USD</p>
                        </div>
                    </div>

                   
                </form>
                {
                        ready?(
                            <button onClick={()=>[
                                setTransferReady(true)
                            ]}>Next</button>
                        ):(
                            <button disabled className='disbaled-btn'>Next</button>
                        )
                    }
            </div>

           
        </div>
        {
                transferReady&&(
                    <TransferConfirm transferData={transferData} setTransferReady={setTransferReady} data={data}/>
                )

            }
        </>
    );
}

export default SendCoin;
