import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useAuthContext } from '../../hooks/UseAuthContext';
import axios from 'axios';
import Receive from './Receive';
import SendCoin from './SendCoin';

function ChooseSend({ setOpenChooseSend }) {
    const [data, setData] = useState(null);
    const { user } = useAuthContext();
    const [tokenData, setTokenData] = useState(() => {
        const storedData = localStorage.getItem('selectedToken');
        return storedData ? JSON.parse(storedData) : null; // Default to null
    });
    const [openReceive, setOpenReceive] = useState(false);
    const [sendCoin, setSendCoin] = useState(() => {
        const storedValue = localStorage.getItem('sendCoin');
        return storedValue ? JSON.parse(storedValue) : false; // Default to false
    });

    useEffect(() => {
        const fetchData = async () => {
            const cachedData = localStorage.getItem('walletTokens');
            if (cachedData) {
                setData(JSON.parse(cachedData));
            } else {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/crypto/get-wallet-tokens`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.token}`
                        }
                    });
                    setData(response.data);
                    localStorage.setItem('walletTokens', JSON.stringify(response.data));
                } catch (error) {
                    console.log(error);
                }
            }
        };

        if (user && user.token) {
            fetchData();
        }
    }, [user]);

    useEffect(() => {
        // Store the selected token in local storage
        localStorage.setItem('selectedToken', JSON.stringify(tokenData));
    }, [tokenData]);

    useEffect(() => {
        // Store the sendCoin state in local storage
        localStorage.setItem('sendCoin', JSON.stringify(sendCoin));
    }, [sendCoin]);

    const coinData = (data) => {
        setTokenData(data);
    };

    return (
        <div className='choose-receive'>
            <div className="choose-receive-top">
                <span onClick={() => {
                    setOpenChooseSend(false);
                    setTokenData(null); // Clear token data on close
                    setSendCoin(false); // Reset sendCoin on close
                }}>
                    <FaArrowLeft />
                </span>
                <p>Send</p>
                <span> </span>
            </div>
            <div className='crypto' onClick={() => {
                setSendCoin(true);
            }}>
                {data && data.slice(0, 8).map((token) => {
                    return (
                        <div className='crypto-token' key={token.id} onClick={() => {
                            coinData(token);
                        }}>
                            <div className="token">
                                <div className="token-img">
                                <img
  src={token.image.startsWith('http')
    ? token.image
    : `${process.env.REACT_APP_API_URL}/images/${token.image}`}
  alt={token.name}
/>
                                </div>
                                <div className='crypto-data'>
                                    <p>{token.symbol} <span className='token-name'>{token.name}</span></p>
                                    <p className='second-token-name'>{token.name}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="receive">
                {tokenData && sendCoin && (
                    <SendCoin data={tokenData} setSendCoin={setSendCoin} />
                )}
            </div>
        </div>
    );
}

export default ChooseSend;
