import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaInfoCircle } from 'react-icons/fa';
import { useAuthContext } from '../../hooks/UseAuthContext';
import ViewNotification from './ViewNotification';
import Loader from '../../components/Loader/Loader';

function Notifications() {
    const [transactions, setTransactions] = useState(null);
    const { user } = useAuthContext();
    const [openNotification, setOpenNotification] = useState(false);
    const [notiData, setNotiData] = useState(null);
    const [loading, setLoading] = useState(true);  // Add loading state

    const handleNotificationClick = (data) => {
        setNotiData(data);
        setOpenNotification(true);
    };

    const goBack = () => {
        window.history.back();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-notifications`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                setTransactions(response.data);
                setLoading(false);  // Data loaded, set loading to false
                console.log(response.data);
            } catch (error) {
                console.log(error);
                setLoading(false);  // Even if error, stop loading
            }
        };

        if (user && user.token) {
            fetchData();
        }
    }, [user]);

    function formatISODate(isoString) {
        const date = new Date(isoString);  // Parse the ISO string into a Date object
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    function truncateMessage(message, wordLimit) {
        const words = message.split(' '); // Split the message into an array of words
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...'; // Return the first 'wordLimit' words followed by '...'
        }
        return message; // Return the message as is if it's shorter than the word limit
    }

    return (
        <>
            <div className='history-data'>
                <div className="choose-receive-top">
                    <span onClick={() => goBack()}>
                        <FaArrowLeft />
                    </span>
                    <p>Notifications</p>
                    <span></span>
                </div>

                {/* Show Loader while loading */}
                {loading ? (
                    <Loader />  // Display loader while fetching
                ) : (
                    <>
                        {transactions && transactions.map((data, index) => (
                            <div className="transaction-data" key={index} onClick={() => handleNotificationClick(data)}>
                                <span className='date'>{formatISODate(data.createdAt)}</span>

                                <div className="transactions">
                                    <div className='arrow-address'>
                                        <span className='arrow'><FaInfoCircle /></span>
                                        <div className='address-data'>
                                            <p>{data.title}</p>
                                            <span>{truncateMessage(data.message, 20)}</span>
                                        </div>
                                    </div>

                                    <div className="amount-data-history">
                                        {data.read === false && <span>&#x2022;</span>}

                                        {data.status === 'pending' && <h4 style={{ color: '#FFA500' }}>{data.status}</h4>}
                                        {data.status === 'approved' && <h4 style={{ color: '#00B26B' }}>{data.status}</h4>}
                                        {data.status === 'rejected' && <h4 style={{ color: '#FF0000' }}>{data.status}</h4>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>

            {openNotification && (
                <ViewNotification data={notiData} setOpenNotification={setOpenNotification} />
            )}
        </>
    );
}

export default Notifications;
