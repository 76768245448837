import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/UseAuthContext'
import blocklogo from '../../resources/images/verdexblock-removebg.png'
function Sidebar({setMenu}) {
    const {user,dispatch}=useAuthContext()
    const navigate=useNavigate()

    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        localStorage.setItem('user', null);
            navigate('/login');
 
    };
  return (
    <div className='wallet-sidebar slide-in-left '>
        
        <div className="menu-items">
        <Link style={{textDecoration:'none', color:'#fff'}}to={'/'}>
        <p>Home</p>
        </Link>
        <Link style={{textDecoration:'none', color:'#fff'}}to={'/market'}>
        <p>Market</p>
        </Link>
        <Link style={{textDecoration:'none', color:'#fff'}}to={'/ticket'}>
        <p>Ticket</p>
        </Link>
        <p onClick={()=>{
             logout()
        }}>Logout</p>

           
           
            
        </div>

       <div className='sidebar-header-section'>
        {/* <img src={blocklogo} alt="" /> */}
       <FaTimes style={{cursor:"pointer"}} onClick={()=>{
            setMenu(false)
        }}/>
       </div>
      
    </div>
  )
}

export default Sidebar
