import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { useAuthContext } from '../../hooks/UseAuthContext';


const AdminRoute = () => {
  const { user, dispatch } = useAuthContext();

  // Check if the user exists and the token is still valid
  if (user) {
    const token = user.token;
    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        // Check if the token has expired
        const currentTime = Date.now() / 1000; // current time in seconds
        if (decodedToken.exp < currentTime) {
          // Token has expired, log out the user
          dispatch({ type: 'LOGOUT' });
          localStorage.setItem('user', null);
          return <Navigate to="/login-admin" />;
        }

      } catch (error) {
        console.error('Error decoding token', error);
        dispatch({ type: 'LOGOUT' });
        localStorage.setItem('user', null);
        return <Navigate to="/login-admin" />;
      }
    }
  } else {
    // If there's no user, redirect to login-admin
    return <Navigate to="/login-admin" />;
  }

  // If everything is valid, render the child components
  return <Outlet />;
};

export default AdminRoute;
