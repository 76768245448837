
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { FaAlignLeft, FaPlus, FaSignOutAlt, FaTimes, FaUserAlt } from 'react-icons/fa';
import { toast } from 'wc-toast';
import AdminConfirm from './AdminConfirm';
import { Link } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import Loader from '../Loader/Loader'; // Import your Loader component
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/UseAuthContext';
import loginLogo from '../../resources/images/verdex-bgremove.png';
import { useDropzone } from 'react-dropzone';

function CustomToken() {
    const navigate = useNavigate();
    const { user, dispatch } = useAuthContext();
    const [sidemenu, setSideMenu] = useState(false);
    const [data, setData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [userId, setUserId] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [dashUser, setDashUser] = useState(false);
    const [formData, setFormData] = useState({
        token: '',
        name:"",
        symbol:"",
        current_price:"",
        low_24:"",
        high_24:"",
        address: '',
        tokenId:'',
        tId:'',
        coinId:'',
        coinAddress:'',
        price_change_percentage_24h:'',
        tokenImg:''
    });
    const onDrop = useCallback((acceptedFiles) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            tokenImg: acceptedFiles[0], // assuming you handle one image only
        }));
        console.log(acceptedFiles);
    }, []);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*', // Accept only images
    })
    const [newCoin,setNewCoin]=useState(false)

    const [loading, setLoading] = useState(true); // Loading state

    // Fetch users when component mounts and whenever updateTrigger or user token changes
    useEffect(() => {
        fetchUsers();
    }, [user.token, updateTrigger]);

    const fetchUsers = async () => {
        setLoading(true); // Start loading
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/get-custom-tokens`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const handleEditClick = (data) => {
        setUserId(data._id);
        setFormData({
            token: data.name, // Assumed token name field
            address: data.address,
            tId:data.id
        });
        setEditMode(true);
    };

    const handleDelete = (data) => {
        console.log(data,userId)
        setUserId(data);
        setConfirm(true);
        
    };

    const onConfirm= async () => {

        try{
            await axios.delete(`${process.env.REACT_APP_API_URL}/admin/delete-custom-token/${userId._id}/${userId.id}`,{
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            toast.success("Coin deleted successfully");
            setConfirm(false)
            setUpdateTrigger((prev) => !prev);
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        }
    };


   
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/admin/edit-custom-token/`, {
                token: formData.token,
                address: formData.address, // Make sure to submit the correct fields
                tokenId:userId,
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            toast.success("Token updated successfully");
            setEditMode(false);
            setUpdateTrigger((prev) => !prev);
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        }
    };

    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        localStorage.setItem('user', null);
        
            navigate('/login-admin');
    };
 const handCoinleSubmit=async(e)=>{
    e.preventDefault();
    const tFormData = new FormData();
        tFormData.append('image', formData.tokenImg);
        tFormData.append('coinId', formData.coinId);
        tFormData.append('symbol', formData.symbol);
        tFormData.append('name', formData.name);
        tFormData.append('current_price', formData.current_price);
        tFormData.append('low_24', formData.low_24);
        tFormData.append('high_24', formData.high_24);
        tFormData.append('price_change_percentage_24h', formData.price_change_percentage_24h);
        tFormData.append('address', formData.coinAddress);

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/crypto/custom-token/`,tFormData, {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        });
        toast.success("Coin added successfully");
        setNewCoin(false);
        setFormData({
            ...formData,
            coinId:'',
            address:''

        })
        setUpdateTrigger((prev) => !prev);
    } catch (error) {
        toast.error("Failed");
        console.error(error);
    }

 }
    return (
        <div className="admin dashboard">
            <div className="dashboard-header">
                <div className="rights-side">
                    <Link to="/" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        <div className="admin-logo">
                            <img src={loginLogo} alt="logo" />
                        </div>
                    </Link>

                    <FaAlignLeft onClick={() => setSideMenu(!sidemenu)} />
                </div>
                <div className="left-side">
                    <FaUserAlt onClick={() => setDashUser((prev) => !prev)} />
                </div>
                {dashUser && (
                    <div className="user-menu">
                        <p className="logout" onClick={logout}><FaSignOutAlt />Logout</p>
                    </div>
                )}
            </div>

            {sidemenu && <AdminSidebar />}

            <div className="main-admin-dash loan-container">
                <div className="table-head">
                    <h2>Custom Tokens</h2>
                   
                            <button  onClick={() =>{
                                                    setNewCoin(true)
                                                }}><FaPlus />Add New</button>
                        
                </div>
                {loading ? (
                    <Loader /> // Show loader while loading
                ) : (
                    <div className="loan-table">
                        <table className="equal-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Walled Address</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ? (
                                    data.map((user) => (
                                        <tr key={user._id}>
                                            <td>{user.name}</td>
                                            <td>{user.address}</td>
                                            <td className="action-tb">
                                                <button onClick={() => handleEditClick(user)}>Edit</button>
                                              
                                                <button onClick={() => handleDelete(user)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="no-loan-message">No Custom Token</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {editMode && (
                <div className="deposits-form">
                    <div className="cancel-depo">
                        <FaTimes onClick={() => setEditMode(false)} />
                    </div>
                    <div className="send-money-container">
                        <form className="send-money-form" onSubmit={handleSubmit}>
                            <h2>Edit Token</h2>
                            <div className="send-money-form-group">
                                <label htmlFor="tId">Token Id</label>
                                <input
                                    type="text"
                                    id="tId"
                                    placeholder="Enter token Id"
                                    value={formData.tId}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="token">Token Name</label>
                                <input
                                    type="text"
                                    id="token"
                                    placeholder="Enter token name"
                                    value={formData.token}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="address">Address</label>
                                <input
                                    type="text"
                                    id="address"
                                    placeholder="Enter address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="send-money-send-btn">Edit</button>
                        </form>
                    </div>
                </div>
            )}

            {confirm && (
                <AdminConfirm
                    message="Are you sure you want to delete?"
                    onConfirm={onConfirm}
                    onCancel={() => setConfirm(false)}
                />
            )}

            {
                newCoin&&(
                    <div className="deposits-form">
                    <div className="cancel-depo">
                        <FaTimes onClick={() => setNewCoin(false)} />
                    </div>
                    <div className="send-money-container">
                        <form className="send-money-form" onSubmit={handCoinleSubmit}>
                            <h2>Add Token</h2>
                            <div className="send-money-form-group">
                                <label htmlFor="coinId">Coin Id (As it is on Coingecko)<span>*</span></label>
                                <input
                                    type="text"
                                    id="coinId"
                                    placeholder="Coin Id (As it is on Coingecko)"
                                    value={formData.coinId}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="symbol">Symbol <span>*</span></label>
                                <input
                                    type="text"
                                    id="symbol"
                                    placeholder="Token Symbol"
                                    value={formData.symbol}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="name">Name <span>*</span></label>
                                <input
                                    type="text"
                                    id="name"
                                    placeholder="Token Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="current_price">Current Price <span>*</span></label>
                                <input
                                    type="number"
                                    id="current_price"
                                    placeholder="Current Token price"
                                    value={formData.current_price}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="low_24">Low 24</label>
                                <input
                                    type="number"
                                    id="low_24"
                                    placeholder="Low 24"
                                    value={formData.low_24}
                                    onChange={handleChange}
                                 
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="high_24">High 24</label>
                                <input
                                    type="number"
                                    id="high_24"
                                    placeholder="High 24"
                                    value={formData.high_24}
                                    onChange={handleChange}
                                   
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="price_change_percentage_24h">Price chnage Percentage 24h</label>
                                <input
                                    type="number"
                                    id="price_change_percentage_24h"
                                    placeholder="Price Change Percentage 24h"
                                    value={formData.price_change_percentage_24h}
                                    onChange={handleChange}
                                    
                                />
                            </div>
                            
                            <div className="send-money-form-group">
                                <label htmlFor="coinAddress">Wallet Address <span>*</span></label>
                                <input
                                    type="text"
                                    id="coinAddress"
                                    placeholder="Enter Wallet Address for this coin"
                                    value={formData.coinAddress}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="send-money-form-group">
                            <label htmlFor="idProof">Upload Any Proof of Identity</label>
                            <div
                                {...getRootProps()}
                                style={{
                                    border: '2px dashed #007bff',
                                    padding: '20px',
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    margin: '1rem 0',
                                    backgroundColor: isDragActive ? '#e0f7fa' : '#f9f9f9',
                                }}
                            >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the image here...</p>
                                ) : (
                                    <>
                                        <p>Drag & drop some images here, or click to select images</p>
                                        <span>{formData.tokenImg?.name}</span>
                                    </>
                                )}
                            </div>
                        </div>
                            <button type="submit" className="send-money-send-btn">Add New</button>
                        </form>
                    </div>
                </div>
                )
            }
        </div>
    );
}

export default CustomToken;



