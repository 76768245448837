import React from 'react'
import { FaArrowLeft, FaCopy, FaInfo } from 'react-icons/fa'
import {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
function Receive({data,setOpenReceive}) {
  return (
    <div className='receiving-crypto'>
      <div className="choose-receive-top">
            <span onClick={()=>{
                setOpenReceive(false)
            }} ><FaArrowLeft/></span>
            <p>Receive</p>
            <span> </span>
        </div>
        <div className="notice-section">
        <div className="notice">
            <span><FaInfo/></span>
            <p>Only send {data.name} ({data.symbol}) assets to this address. Other assets will be lost forever</p>
        </div>
        </div>
       {
        data&&(
            <div className='receive-data'>
            <div className="receive-token-data">
            <img src={data.image} alt="" />
             <p>{data.symbol}</p>
            </div>
            <div className="qrcode">
            <QRCodeCanvas value={data.address} size={256} level={"H"} includeMargin={true} />
            <p>{data.address}</p>
            </div>
        </div>
        )
       }

<div className="send-receive">
                <div className="send" >
                    <span><FaCopy /></span>
                    
                </div>
                <p>Copy Address</p>
            </div>
    </div>


  )
}

export default Receive
