import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaAlignLeft, FaPlus, FaSignOutAlt, FaTimes, FaUserAlt } from 'react-icons/fa';
import { toast } from 'wc-toast';
import AdminConfirm from './AdminConfirm';
import { Link } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import Loader from '../Loader/Loader'; // Import your Loader component
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/UseAuthContext';
import loginLogo from '../../resources/images/verdex-bgremove.png';
function Transactions() {
    const navigate = useNavigate();
    const { user, dispatch } = useAuthContext();
    const [sidemenu, setSideMenu] = useState(false);
    const [data, setData] = useState([]);
    const [users,setUsers]=useState([])
    const [editMode, setEditMode] = useState(false);
    const [userId, setUserId] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [dashUser, setDashUser] = useState(false);
    const [newTransaction,setNewTransaction]=useState(false)
    const [formData, setFormData] = useState({
        status: '',
        amount: '',
        userId:'',
        newStatus:'',
       network:''
    });

    const [loading, setLoading] = useState(true); // Loading state

    // Fetch users when component mounts and whenever updateTrigger or user token changes
    useEffect(() => {
        fetchUsers();
    }, [user.token, updateTrigger]);
const handTransactionSubmit=async(e)=>{
    e.preventDefault()

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/crypto/deposit/${formData.userId}`,{
            amount:formData.amount,
            status:formData.newStatus,
            network:formData.network,

        }, {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        });
        toast.success('Transaction Created Successfully');
        setNewTransaction(false);
        setUpdateTrigger((prev) => !prev);
    } catch (error) {
        console.error(error);
    }

}
    useEffect(() => {
        const fetchUsers = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-users`, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            });
            console.log(response.data)
            setUsers(response.data);
          } catch (error) {
            console.error(error);
          }
        };
    
        if (user && user.token) {
          fetchUsers();
        }
      }, [user]);

    const fetchUsers = async () => {
        setLoading(true); // Start loading
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/transaction`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setData(response.data);
            console.log(response.data)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        
        setFormData((prev) => ({ ...prev, [id]: value }));
        console.log(formData)
    };

    const handleEditClick = (data) => {
        setUserId(data);
       console.log(data)
        setEditMode(true);
    };

    const handleDelete = (id) => {
        setUserId(id);
        setConfirm(true);
    };

    const onConfirmDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/crypto/delete-trx/${userId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            toast.success('Transaction deleted successfully');
            setConfirm(false);
            setUpdateTrigger((prev) => !prev);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/admin/modify-transaction/`, {
                tId: userId._id,
                modification: formData.status,
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            toast.success("Status updated successfully");
            setEditMode(false);
            setUpdateTrigger((prev) => !prev);
        } catch (error) {
            toast.error("Failed")
            console.error(error);
        }
    };

    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        localStorage.setItem('user', null);
        
            navigate('/login-admin');
    };

    return (
        <div className="admin dashboard">
            <div className="dashboard-header">
                <div className="rights-side">
                <Link to="/" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        <div className="admin-logo">
                            <img src={loginLogo} alt="logo" />
                        </div>
                    </Link>
                  
                    <FaAlignLeft onClick={() => setSideMenu(!sidemenu)} />
                </div>
                <div className="left-side">
                    <FaUserAlt onClick={() => setDashUser((prev) => !prev)} />
                </div>
                {dashUser && (
                    <div className="user-menu">
                        <p className="logout" onClick={logout}><FaSignOutAlt />Logout</p>
                    </div>
                )}
            </div>

            {sidemenu && <AdminSidebar />}

            <div className="main-admin-dash loan-container">
                <div className="table-head">
                    <h2>Transactions</h2>
                    <button  onClick={() =>{
                                                    setNewTransaction(true)
                                                }}><FaPlus />Add New Deposit TX</button>
                </div>
                {loading ? (
                    <Loader /> // Show loader while loading
                ) : (
                    <div className="loan-table">
                        <table className="equal-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Type</th>
                                    <th>Network</th>
                                    <th>Address</th>
                                    <th>amount</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ? (
                                    data.map((user) => (
                                        <tr key={user._id}>
                                             <td>{user.user.name}</td>
                                             <td>{user.user.email}</td>
                                            <td>{user.type||'null'}</td>
                                            
                                            <td>{user.network}</td>
                                            <td>{user.address||'null'}</td>
                                            <td>{user.amount}</td>
                                            <td>{user.status}</td>
                                          
                                            <td className="action-tb">
                                                <button onClick={() => handleEditClick(user)}>Edit</button>
                                                <button onClick={() => handleDelete(user._id)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="no-loan-message">No Users</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {editMode && (
                <div className="deposits-form">
                    <div className="cancel-depo">
                        <FaTimes onClick={() => setEditMode(false)} />
                    </div>
                    <div className="send-money-container">
                        <form className="send-money-form" onSubmit={handleSubmit}>
                            <h2>Edit Transaction Status</h2>
                            <div className="send-money-form-group">
                                <label htmlFor="status">Status<span>*</span></label>
                                <select
                                    id="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Select a token</option>
                                    <option value="pending" >Pending</option>
                                    <option value="approved" >Approved</option>
                                    <option value="rejected" >Rejected</option>
                                    
                                </select>
                            </div>
                            <button type="submit" className="send-money-send-btn">Update Status</button>
                        </form>
                    </div>
                </div>
            )}

            {confirm && (
                <AdminConfirm
                    message="Are you sure you want to delete?"
                    onConfirm={onConfirmDelete}
                    onCancel={() => setConfirm(false)}
                />
            )}
              {
                newTransaction&&(
                    <div className="deposits-form">
                    <div className="cancel-depo">
                        <FaTimes onClick={() => setNewTransaction(false)} />
                    </div>
                    <div className="send-money-container">
                        <form className="send-money-form" onSubmit={handTransactionSubmit}>
                            <h2>Add a new Deposit Transaction</h2>

                            <div className="send-money-form-group">
              <label htmlFor="userId">Choose User<span>*</span></label>
              <select
                id="userId"
                value={formData.userId}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select One</option>
                {users.length > 0 && users.map((user) => (
                  <option key={user._id} value={user._id}>{user.name}</option>
                ))}
              </select>
            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="amount">Amount <span>*</span></label>
                                <input
                                    type="text"
                                    id="amount"
                                    placeholder="Deposit Amount"
                                    value={formData.amount}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="newStatus">Status<span>*</span></label>
                                <select
                                    id="newStatus"
                                    value={formData.newStatus}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Select Deposit Status</option>
                                    <option value="pending" >Pending</option>
                                    <option value="approved" >Approved</option>
                                    <option value="rejected" >Rejected</option>
                                    
                                </select>
                            </div>
                            <div className="send-money-form-group">
                                <label htmlFor="network">Network (bitcoin,tether)</label>
                                <input
                                    type="text"
                                    id="network"
                                    placeholder="Enter network"
                                    value={formData.network}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="send-money-send-btn">Add New</button>
                        </form>
                    </div>
                </div>
                )
            }
        </div>
    );
}

export default Transactions;
