import React, { useEffect, useState } from 'react';
import './wallet.css';
import { FaAlignLeft, FaArrowDown, FaArrowUp, FaBell, FaCopy, FaExchangeAlt, FaEye, FaEyeSlash, FaHistory } from 'react-icons/fa';
import { useAuthContext } from '../../hooks/UseAuthContext';
import axios from 'axios';
import ChooseReceive from './ChooseReceive';
import CoinPage from './CoinPage';
import ChooseSend from './ChooseSend';
import Swap from './Swap';
import History from './History';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Loader from '../../components/Loader/Loader';
function Wallet() {
    const [menu,setMenu]=useState(false)
    const [seeBalance, setSeeBalance] = useState(() => {
        const storedValue = localStorage.getItem('seeBalance');
        return storedValue ? JSON.parse(storedValue) : true; // Default to true
    });
    const [data, setData] = useState([]);
    const [openChooseReceive, setOpenChooseReceive] = useState(() => {
        const storedValue = localStorage.getItem('openChooseReceive');
        return storedValue ? JSON.parse(storedValue) : false; // Default to false
    });
    const [openChooseSend, setOpenChooseSend] = useState(() => {
        const storedValue = localStorage.getItem('openChooseSend');
        return storedValue ? JSON.parse(storedValue) : false; // Default to false
    });

    const [openCoinpage,setOpenCoinpage] = useState(() => {
        const storedValue = localStorage.getItem('openCoinpage');
        return storedValue ? JSON.parse(storedValue) : false; // Default to false
    });
  
    const { user } = useAuthContext();
    const [tokenData, setTokenData] = useState(null);
    const [balances, setBalances] = useState(null);
    const [notifications,setNotifications]=useState(0)
    
    const [openSwap, setOpenSwap] = useState(() => {
        const storedValue = localStorage.getItem('openSwap');
        return storedValue ? JSON.parse(storedValue) : false; // Default to false
    });
    const [openTransaction, setOpenTransaction] = useState(() => {
        const storedValue = localStorage.getItem('openTransaction');
        return storedValue ? JSON.parse(storedValue) : false; // Default to false
    });


    // Update local storage whenever the balance visibility changes
    useEffect(() => {
        localStorage.setItem('seeBalance', JSON.stringify(seeBalance));
    }, [seeBalance]);

    // Update local storage whenever the ChooseReceive modal state changes
    useEffect(() => {
        localStorage.setItem('openChooseReceive', JSON.stringify(openChooseReceive));
    }, [openChooseReceive]);

    // Update local storage whenever the ChooseSend modal state changes
    useEffect(() => {
        localStorage.setItem('openChooseSend', JSON.stringify(openChooseSend));
    }, [openChooseSend]);

    useEffect(() => {
        localStorage.setItem('openSwap', JSON.stringify(openSwap));
    }, [openSwap]);
    
    useEffect(() => {
        localStorage.setItem('openTransaction', JSON.stringify(openTransaction));
    }, [openTransaction]);
    useEffect(() => {
        localStorage.setItem('openCoinpage', JSON.stringify(openCoinpage));
        const storedValue = localStorage.getItem('tokenData');
        if (storedValue) {
            setTokenData(JSON.parse(storedValue));
        }
    }, [openCoinpage]);

    // Function to calculate the percentage change for a single token
    const calculateChange = (token) => {
        const percentageIncrease = token.current_price > token.low_24h ? ((token.current_price - token.low_24h) / token.low_24h) * 100 : 0;
        const percentageDecrease = token.current_price < token.high_24h ? ((token.high_24h - token.current_price) / token.high_24h) * 100 : 0;
        const overallChange = token.price_change_percentage_24h || 0;
        return { percentageIncrease, percentageDecrease, overallChange };
    };

    // Function to render the percentage change with color
    const renderChange = (change) => {
        if (change > 0) {
            return <span style={{ color: 'rgb(0, 178, 107)' }}>+{change.toFixed(2)}%</span>;
        } else if (change < 0) {
            return <span style={{ color: 'red' }}>{change.toFixed(2)}%</span>;
        } else {
            return <span>{change.toFixed(2)}%</span>;
        }
    };

    // Calculate the amount of token from USDT balance
    const calculateTokenAmount = (balance,name, currentPrice) => {
        console.log(name)
        if (balance > 0 && currentPrice > 0) {
            if (name === 'ethereum') {
                return (balance / currentPrice).toFixed(6);
            } else if (name === 'bitcoin') {
                return (balance / currentPrice).toFixed(8);
            } else if (name === 'tether') {
                return (balance / currentPrice).toFixed(2);
            } else {
                return (balance / currentPrice).toFixed(3)
            }
             // Adjust decimal places as needed
        }
        return '0';
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const cachedData = localStorage.getItem('walletTokens');
                console.log(cachedData)
                if (cachedData&&cachedData!=="null") {
                    console.log("in cache")
                    setData(JSON.parse(cachedData));
                } else {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/crypto/get-wallet-tokens`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.token}`
                        }
                    });
                    console.log(response.data)
                    setData(response.data);
                    localStorage.setItem('walletTokens', JSON.stringify(response.data));
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (user && user.token) {
            fetchData();
        }
    }, [user]);

    const coinData = (data) => {
        setTokenData(data);
        setOpenCoinpage(true);
        localStorage.setItem('tokenData', JSON.stringify(data));
    };
    useEffect(()=>{
        const fetchData = async () => {
            try {
             
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-notifications`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.token}`
                        }
                    });

                    const dataLength=response.data.filter(noti=>noti.read==false)
                   setNotifications(dataLength.length);
            
            } catch (error) {
                console.log(error);
            }
        };
    
        if (user && user.token) {
            fetchData();
        }
      },[])
    useEffect(() => {
        const fetchBalances = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-user-balance`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                setBalances(response.data);
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        if (user && user.token) {
            fetchBalances();
        }
    }, [user,openSwap]);

    return (
        <>
           {
            data&&balances?<div >
            <div className='wallet'>
                 {/* Top bar and wallet information */}
                 <div className="top-level">
                     <span onClick={()=>{
                         setMenu(true)
                     }}><FaAlignLeft /></span>
                     <p>Wallet</p>
                     <div className="wallet-noti">
                     <Link style={{textDecoration:'none',color:'white'}} to='/notifications'><span><FaBell /></span>
                     {
                         notifications>0&&(
                             <p>{notifications}</p>
                         )
                     }</Link>
                     </div>
                 </div>
 
                 <div className="user-wallet">
                     <div className='main-wallet'>
                         <span style={{ fontSize: '15px' }}>Main Wallet</span>
                         <div>
                             {
                                 seeBalance ? (
                                     <>
                                         {
                                             balances && (
                                                 <p>${balances.totalBalance.toFixed(2)}</p>
                                             )
                                         }
                                         <span><FaEye onClick={() => setSeeBalance(false)} /></span>
                                     </>
                                 ) : (
                                     <>
                                         <p>*****</p>
                                         <span><FaEyeSlash onClick={() => setSeeBalance(true)} /></span>
                                     </>
                                 )
                             }
                         </div>
                     </div>
                     <div className='user-wallet-copy'>
                         <span><FaCopy /></span>
                     </div>
                 </div>
 
                 {/* Send, Receive, Swap, History */}
                 <div className="send-receive">
                     <div className="send" onClick={() => {
                         setOpenChooseSend(true);
                         setOpenChooseReceive(false);
                     }}>
                         <span><FaArrowDown /></span>
                         <p>Send</p>
                     </div>
                     <div className="receive" onClick={() => {
                         setOpenChooseSend(false);
                         setOpenChooseReceive(true);
                     }}>
                         <span><FaArrowUp /></span>
                         <p>Receive</p>
                     </div>
                     <div className="swap" onClick={() => {
                         setOpenSwap(true);
                     }}>
                         <span><FaExchangeAlt /></span>
                         <p>Swap</p>
                     </div>
                     <div className="history" onClick={() => {
                         setOpenTransaction(true);
                     }}>
                         <span><FaHistory /></span>
                         <p>History</p>
                     </div>
                 </div>
 
                 {/* Display Crypto Tokens */}
                 <div className='crypto'>
    <h4>Crypto</h4>
    {data && balances && data
        .map((token) => {
            const balance = balances.balances.find((id) => id.token === token.id);
            return { ...token, balance: balance ? balance.balance : 0 }; // Include balance in each token object
        })
        .sort((a, b) => b.balance - a.balance) // Sort tokens by balance in descending order
// Limit to the top 8 tokens if needed
        .map((token) => {
            const { percentageIncrease, percentageDecrease, overallChange } = calculateChange(token);
            const tokenAmount = calculateTokenAmount(token.balance, token.symbol, token.current_price);
            return (
                <div className='crypto-token' key={token.id} onClick={() => coinData(token)}>
                    <div className="token">
                        <div className="token-img">
                            <img
                                src={token.image.startsWith('h') ? token.image : `${process.env.REACT_APP_API_URL}/images/${token.image}`}
                                alt={token.name}
                            />
                        </div>
                        <div className='crypto-data'>
                            <p>{token.symbol} <span className='token-name'>{token.name}</span></p>
                            <p className='token-price'>${token.current_price.toFixed(2)} <span>{renderChange(overallChange)}</span></p>
                        </div>
                    </div>
                    <div className="crypto-balances">
                        <p>{tokenAmount}</p>
                        <span>${token.balance.toFixed(2)}</span>
                    </div>
                </div>
            );
        })}
</div>

             </div>
 
             {/* Modal for ChooseReceive */}
             {openChooseReceive && (
                 <div className="wallet-choose-receive">
                     <ChooseReceive setOpenChooseReceive={setOpenChooseReceive} />
                 </div>
             )}
 
             {/* Modal for ChooseSend */}
             {openChooseSend && (
                 <div>
                     <ChooseSend setOpenChooseSend={setOpenChooseSend} />
                 </div>
             )}
 
             {/* Modal for CoinPage */}
             {openCoinpage && tokenData && (
                 <div className="opencoinpage">
                     <CoinPage data={tokenData} setOpenCoinpage={setOpenCoinpage} />
                 </div>
             )}
 
             {openSwap && (
                 <Swap tokens={data} setOpenSwap={setOpenSwap} />
             )}
 
             {openTransaction && (
                 <div>
                     <History setOpenTransaction={setOpenTransaction} />
                 </div>
             )}
 
             {
                 menu&&(
                     <Sidebar setMenu={setMenu}/>
                 )
             }
            </div>:<div><Loader/></div>
           }
        </>
    );
}

export default Wallet;
