import React, { useEffect, useState } from 'react';
import { FaArrowDown, FaArrowLeft, FaArrowUp, FaExchangeAlt, FaHistory } from 'react-icons/fa';
import SendCoin from './SendCoin';
import Receive from './Receive';
import { useAuthContext } from '../../hooks/UseAuthContext';
import Swap from './Swap';
import axios from 'axios';
import History from './History';

function CoinPage({ setOpenCoinpage }) {
    const [sendCoin, setSendCoin] = useState(false)
    const [data, setData] = useState(null);
    const [openTransaction,setOpenTransaction]=useState(null)
    const [openReceive, setOpenReceive] =  useState(false);
    const [tokenData, setTokenData] = useState(null);
    const [openSwap, setOpenSwap] = useState(() => {
        const storedValue = localStorage.getItem('openSwap');
        return storedValue ? JSON.parse(storedValue) : false; // Default to false
    });
    const [balances, setBalances] = useState(null);
    const [coinBalance, setCoinBalance] = useState(0);
    const [coinUSDBalance, setCoinUSDBalance] = useState(0);
    const { user } = useAuthContext();

    // Load state from localStorage on page load
    useEffect(() => {
        const storedData = localStorage.getItem('tokenData');
        // const storedSendCoin = localStorage.getItem('sendCoin');
        // const storedOpenReceive = localStorage.getItem('openReceive');
        // const storedOpenSwap = localStorage.getItem('openSwap');
        
        if (storedData) {
            setData(JSON.parse(storedData));
        }

        // setSendCoin(storedSendCoin);
        // setOpenReceive(storedOpenReceive);
        // setOpenSwap(storedOpenSwap);
    }, []);

    // Store open state of components in localStorage
    useEffect(() => {
        localStorage.setItem('sendCoin', sendCoin);
        localStorage.setItem('openReceive', openReceive);
        localStorage.setItem('openSwap', openSwap);
    }, [sendCoin, openReceive, openSwap]);

    useEffect(() => {
        const storedValue = localStorage.getItem('tokenData');
        if (storedValue) {
            setData(JSON.parse(storedValue));
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-user-balance`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                setBalances(response.data);
                const balance = response.data.balances.find(id => id.token === data?.id);
                setCoinUSDBalance(balance.balance);

                const toPrice = balance.balance / data.current_price;
                if (data.id === 'ethereum') {
                    setCoinBalance(toPrice.toFixed(6));
                } else if (data.id === 'bitcoin') {
                    setCoinBalance(toPrice.toFixed(8));
                } else if (data.id === 'tether') {
                    setCoinBalance(balance.balance.toFixed(2));
                } else {
                    setCoinBalance(toPrice.toFixed(3));
                }

            } catch (error) {
                console.log(error);
            }
        };

        if (user && user.token && data) {
            fetchData();
        }
    }, [user, data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const cachedData = localStorage.getItem('walletTokens');
                if (cachedData) {
                    setTokenData(JSON.parse(cachedData));
                } else {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/crypto/get-wallet-tokens`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.token}`
                        }
                    });
                    setTokenData(response.data);
                    localStorage.setItem('walletTokens', JSON.stringify(response.data));
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (user && user.token) {
            fetchData();
        }
    }, [user]);

    return (
        <>
            <div className='coinpage'>
                <div className="choose-receive-top">
                    <span onClick={() => {
                        setOpenCoinpage(false);
                    }}><FaArrowLeft /></span>
                    <div>
                        <p>{data?.symbol}</p>
                        <span>{data?.name}</span>
                    </div>
                    <span></span>
                </div>

                <div className="coinpage-cryptosection">
                    <div className="cryptosection-data">
                    <img
  src={data?.image.startsWith('http')
    ? data?.image
    : `${process.env.REACT_APP_API_URL}/images/${data?.image}`}
  alt={data?.name}
/>
                        <p>{coinBalance} {data?.symbol}</p>
                        <span>${coinUSDBalance.toFixed(2)}</span>
                    </div>

                    <div className="send-receive">
                        <div className="send" onClick={() => setSendCoin(true)}>
                            <span><FaArrowDown /></span>
                            <p>Send</p>
                        </div>
                        <div className="receive" onClick={() => setOpenReceive(true)}>
                            <span><FaArrowUp /></span>
                            <p>Receive</p>
                        </div>
                        <div className="swap" onClick={() => setOpenSwap(true)}>
                            <span><FaExchangeAlt /></span>
                            <p>Swap</p>
                        </div>
                        <div className="history" onClick={() => setOpenTransaction(true)}>
                            <span><FaHistory /></span>
                            <p>History</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Render SendCoin, Receive, and Swap components if open */}
            {sendCoin && <SendCoin data={data} setSendCoin={setSendCoin} />}
            {openReceive && <Receive data={data} setOpenReceive={setOpenReceive} />}
            {openSwap && <Swap tokens={tokenData} setOpenSwap={setOpenSwap} />}
            {openTransaction&& <History setOpenTransaction={setOpenTransaction}/>}
        </>
    );
}

export default CoinPage;
