import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Signup from './Pages/Signup/Signup';
import { toast } from 'wc-toast';
import Signin from './Pages/Signin/Signin';
import Home from './Pages/Home/Home';
import Wallet from './Pages/Wallet/Wallet';
import AdminDashboard from './components/Admin Dashboard/AdminDashboard';
import LoginAdmin from './components/Admin Dashboard/LoginAdmin';
import SendCoin from './Pages/Wallet/SendCoin';
import Users from './components/Admin Dashboard/Users';
import Tokens from './components/Admin Dashboard/Tokens';
import Tickets from './Pages/Ticket/Ticket';
import Notify from './components/Admin Dashboard/Notify';
import Notifications from './Pages/Wallet/Notifications';
import Transactions from './components/Admin Dashboard/Transactions';
import Market from './Pages/Market/Market';
import { useAuthContext } from './hooks/UseAuthContext';
import PrivateRoute from './components/Private Route/PrivateRoute';
import AdminRoute from './components/Private Route/AdminRoute';
import ForgotPassword from './Pages/Forgot Passsword/ForgotPassword';
import ResetPassword from './Pages/Forgot Passsword/ResetPassword';
import CustomToken from './components/Admin Dashboard/CustomToken';

function App() {
  const { user, dispatch } = useAuthContext();
  const clearIntervalInMs = 60 * 60 * 24 * 2 * 1000;

  // Function to clear localStorage and set the timestamp
  const clearLocalStorage = () => {
    localStorage.setItem('walletTokens', null);
      console.log("LocalStorage cleared");
      const currentTime = new Date().getTime();
      localStorage.setItem('lastClearTime', currentTime);
  };

  // Function to check if it's time to clear localStorage
  const checkAndClearLocalStorage = () => {
      const lastClearTime = localStorage.getItem('lastClearTime');
      const currentTime = new Date().getTime();

      // If localStorage has never been cleared or the interval has passed, clear it
      if (!lastClearTime || currentTime - lastClearTime > clearIntervalInMs) {
          clearLocalStorage();
      }
  };

  useEffect(() => {
      // Check and clear localStorage when the app loads
      checkAndClearLocalStorage();

      // Set an interval to clear localStorage periodically
      const intervalId = setInterval(() => {
          checkAndClearLocalStorage();
      }, clearIntervalInMs);

      // Cleanup interval on unmount
      return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <wc-toast />
      <Routes>
  <Route path='/' element={<Home />} />
  
  {/* Public Routes */}
  <Route path='/signup' element={user && user !== 'null' ? <Wallet /> : <Signup />} />
  <Route path='/login' element={user && user !== 'null' ? <Wallet /> : <Signin />} />
  <Route path='/login-admin' element={<LoginAdmin />} />
  <Route path='/market' element={<Market />} />
  <Route path='/ticket' element={<Tickets />} /> 
  <Route path='/forgot-password' element={!user || user === 'null' ? <ForgotPassword /> : <Wallet />} />
  <Route path='/reset-password' element={!user || user === 'null' ? <ResetPassword /> : <Wallet />} />
  
  {/* Private Routes - User needs to be authenticated */}
  <Route element={<PrivateRoute user={user} />}>
    <Route path='/wallet' element={<Wallet />} />
    <Route path='/wallet/send' element={<SendCoin />} />
    <Route path='/notifications' element={<Notifications />} />
  </Route>

  {/* Admin Routes - Only Admins can access */}
  <Route element={<AdminRoute user={user} />}>
    <Route path='/admin' element={<AdminDashboard />} />
    <Route path='/admin/users' element={<Users />} />
    <Route path='/admin/tokens' element={<Tokens />} />
    <Route path='/admin/custom-tokens' element={<CustomToken/>} />
    <Route path='/admin/notify' element={<Notify />} />
    <Route path='/admin/transactions' element={<Transactions />} />
  </Route>
</Routes>

    </div>
  );
}

export default App;
