import axios from 'axios';
import React, { useEffect } from 'react'
import { FaArrowLeft, FaInfoCircle } from 'react-icons/fa'
import { useAuthContext } from '../../hooks/UseAuthContext';

function ViewNotification({data,setOpenNotification}) {
    const {user}=useAuthContext()

    function formatISODate(isoString) {
        const date = new Date(isoString);  // Parse the ISO string into a Date object
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    useEffect(()=>{
        axios.put(`${process.env.REACT_APP_API_URL}/user/read-notification/${data._id}`,{},{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }).then(response=>{
            console.log("read")
        }).catch(error=>{
            console.log(error)
        })

    },[])
  return (
    <div className="notification">
        <div className='history-data'>
         <div className="choose-receive-top">
                <span onClick={() => {
                    setOpenNotification(false)
                }}>
                    <FaArrowLeft />
                </span>
                <p>{data.title}</p>
                <span></span>
            </div>


        <div className="transaction-data">
            {/* <span className='date'>{formatISODate(data.createdAt)}</span> */}

            <div className="transactions">
  
  <div className='arrow-address'>
      

         <div className='address-data'>
         {/* <p>{data.title}</p> */}
         <span>{data.message}</span>
         </div>
    
  </div>

  <div className="amount-data-history">
      {/* <p>{data.network}</p>
      <span>${data.amount}</span> */}


{/* {
    data.status=='pending'&&(
       <h4 style={{color:'#FFA500'}}>{data.status}</h4>
    )
} */}
    
{/* {
    data.status=='approved'&&(
       <h4 style={{color:'##00B26B'}}>{data.status}</h4>
    )
} */}
  
{/* {
    data.status=='rejected'&&(
       <h4 style={{color:'#FF0000'}}>{data.status}</h4>
    )
} */}
    
  </div>
</div>
        </div>
        
       

    </div>
    </div>
  )
}

export default ViewNotification
