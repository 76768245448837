import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight, FaArrowDown, FaArrowLeft, FaArrowUp } from 'react-icons/fa';
import { useAuthContext } from '../../hooks/UseAuthContext';
import Loader from '../../components/Loader/Loader';

function History({setOpenTransaction}) {
    const [transactions,setTransactions]=useState(null)
    const {user}=useAuthContext()

    useEffect(()=>{
        const fetchData = async () => {
            try {
             
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-transaction`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.token}`
                        }
                    });
                    setTransactions(response.data);
                    console.log(response.data)
            
            } catch (error) {
                console.log(error);
            }
        };
    
        if (user && user.token) {
            fetchData();
        }
      },[])

      function formatISODate(isoString) {
        const date = new Date(isoString);  // Parse the ISO string into a Date object
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
  return (
   <>

  
    <div className='history-data'>
  {
    transactions?<>
        <div className="choose-receive-top">
           <span onClick={() => {
               setOpenTransaction(false)
           }}>
               <FaArrowLeft />
           </span>
           <p>History</p>
           <span></span>
       </div>
{

transactions&&transactions.map((data,index)=>(

   <div className="transaction-data">
       <span className='date'>{formatISODate(data.createdAt)}</span>

       <div className="transactions">

<div className='arrow-address'>
 
 {
   data.type=='deposit'?(
       <span className='arrow'><FaArrowDown/></span>
   ):(
       <span className='arrow'><FaArrowUp/></span>
   )
 }
    <div className='address-data'>
    <p>Transfer</p>
    {
       data.type=='deposit'?(
<span>To:Wallet</span>
       ):(
           <span>To:{data.address}</span>
       )
    }
    
    </div>

</div>

<div className="amount-data-history">
 <p>{data.network}</p>
 <span>${data.amount}</span>


{
data.status=='pending'&&(
  <h4 style={{color:'#FFA500'}}>{data.status}</h4>
)
}

{
data.status=='approved'&&(
  <h4 style={{color:'##00B26B'}}>{data.status}</h4>
)
}

{
data.status=='rejected'&&(
  <h4 style={{color:'#FF0000'}}>{data.status}</h4>
)
}

</div>
</div>
   </div>
   
  
))
}
    </>:<><Loader/></>
  }
</div>
   
   </>
  )
}

export default History
